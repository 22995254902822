<template>
  <div class="p-pegas">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :sm="16" :xs="24">
            <div class="caz-blocks-sarcho-title">
              <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">   {{ $t("message.online_works") }}</div>
              <div class="block-sarche">
                <div class="header__search d-flex">
                  <!-- <crm-input
                    :size="'small'"
                    :className="'w100'"
                    v-model="filterForm.search"
                    :class="mode ? 'input__day' : 'input__night'"
                    :icon="'el-icon-search'"
                  ></crm-input> -->
                  <div class="mx-3">
                    <select-staff
                      v-model="filterForm.staff_id"
                      :class="mode ? 'input__day' : 'input__night'"
                      :id="filterForm.staff_id"
                    ></select-staff>
                  </div>
                  <div class="mx-3">
                    <el-date-picker
                      v-model="filterForm.from_date"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.from')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                  <div class="mx-3">
                    <el-date-picker
                      v-model="filterForm.to_date"
                      :class="mode ? 'input__day' : 'input__night'"
                      type="date"
                      :placeholder="$t('message.before')"
                      size="small"
                      :format="'dd.MM.yyyy'"
                      :value-format="'dd.MM.yyyy'"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :sm="8" :xs="24" class="flex-style text-right mbtop5">
            <crm-create-and-column-settings
              :permission="$options.name"
              :class="mode ? 'button__settingsday' : 'button__settingsnight'"
              @c-create="drawerCreate = false"
              :can_create="false"
              :columns="columns"
              @c-change="updateColumn"
            >
            </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart">
        <table :class="mode ? 'table__myday' : 'table__mynight'" class="table-my-code table-bordered" v-loading="loadingData">
          <thead>
            <tr>
              <th class="w50p" v-if="columns.id.show">
                {{ columns.id.title }}
              </th>
              <th v-if="columns.staff_id.show">
                {{ columns.staff_id.title }}
              </th>
              <th v-if="columns.reason.show">
                {{ columns.reason.title }}
              </th>
              <th v-if="columns.status.show">
                {{ columns.status.title }}
              </th>
              <th v-if="columns.date.show">
                {{ columns.date.title }}
              </th>
              <th v-if="columns.work_time.show">
                {{ columns.work_time.title }}
              </th>
              <th v-if="columns.created_at.show">
                {{ columns.created_at.title }}
              </th>
            </tr>
            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                <el-input
                  clearable
                  size="mini"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.id"
                  :placeholder="columns.id.title"
                  class="id_input"
                ></el-input>
              </th>
              <th v-if="columns.staff_id.show">
                <select-staff
                  v-model="filterForm.staff_id"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  :id="filterForm.staff_id"
                ></select-staff>
              </th>
              <th v-if="columns.reason.show">
                <crm-input
                  :placeholder="columns.reason.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.reason"
                ></crm-input>
              </th>
              <th v-if="columns.status.show">
                <crm-input
                  :placeholder="columns.status.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.status"
                ></crm-input>
              </th>

              <th v-if="columns.date.show">
                <crm-date-picker
                  :placeholder="columns.date.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.date"
                ></crm-date-picker>
              </th>

              <th v-if="columns.work_time.show">
             
              </th>
              <th v-if="columns.created_at.show">
                <crm-date-picker
                  :placeholder="columns.created_at.title"
                  :class="mode ? 'filter__day' : 'filter__night'"
                  v-model="filterForm.created_at"
                ></crm-date-picker>
              </th>
            </tr>
          </thead>
          <transition-group name="flip-list" tag="tbody">
            <tr
              v-for="complaint in list"
              :key="complaint.id"
              class="cursor-pointer">
              <td v-if="columns.id.show">{{ complaint.id }}</td>
              <td v-if="columns.staff_id.show">
                {{
                  complaint.staff
                    ? complaint.staff.name + " " + complaint.staff.last_name
                    : ""
                }}
              </td>
              <td v-if="columns.reason.show">
                {{ complaint.reason }}
              </td>
               <td v-if="columns.status.show">
                <el-button v-if="complaint.status == 'waiting'" type="warning" size="medium"    class="remove-button"> {{$t('message.confirm')}}</el-button>
                <el-button v-if="complaint.status == 'accept'" type="success" size="medium"    class="remove-button"> {{$t('message.approved')}}</el-button>
                <el-button v-if="complaint.status == 'deny'" type="danger" size="medium"    class="remove-button"> {{$t('message.rejected')}}</el-button>      
              </td>
              <td v-if="columns.date.show">
                {{ complaint.date }}
              </td>
              <td v-if="columns.work_time.show">
                {{ complaint.from_time }} - {{ complaint.to_time }}
              </td>
              <td v-if="columns.created_at.show">
                {{ complaint.created_at }}
              </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import list from "@/utils/mixins/list";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "SkillController",
  mixins: [list],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      list: "onlineWork/list",
      columns: "onlineWork/columns",
      pagination: "onlineWork/pagination",
      statues: "onlineWork/statues",
      filter: "onlineWork/filter",
      sort: "onlineWork/sort",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
   mounted() {
  },
  methods: {
    ...mapActions({
      updateList: "onlineWork/onlineWork",
      setPagination: "onlineWork/setPagination",
      updateSort: "onlineWork/updateSort",
      updateFilter: "onlineWork/updateFilter",
      updateColumn: "onlineWork/updateColumn",
      updatePagination: "onlineWork/updatePagination",
      show: "onlineWork/show",
      empty: "onlineWork/empty",
      delete: "onlineWork/destroy",
      refreshData: "onlineWork/refreshData",
    }),
  },
};
</script>
